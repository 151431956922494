<template>
  <service-menu current="logistics"/>
  <div class="service-top">
    <div class="content">
      <h1>国際郵送サービス</h1>
      <p>
        買い付けた商品はSellerPort倉庫で大切に保管。<br>
        物流加工後、ご希望の郵送方法でお届けします。
      </p>
    </div>
  </div>
  <div class="flows">
    <h1>
      国際郵送サービスの流れ
    </h1>
    <div class="box pc">
      <div class="row">
        <div class="supplier">
          <img src="@/assets/supplier-a.svg"/>
          <label>サプライヤー</label>
        </div>
        <div class="connector-left"></div>
        <div class="center">
          <img src="@/assets/sellerport-warehouse.svg"/>
        </div>      
        <div class="airplane">
          <img src="@/assets/airplane.svg"/>
          <label>エア便</label>
        </div>
      </div>
      <div class="row row-down">
        <div class="supplier">
          <img src="@/assets/supplier-b.svg"/>
          <label>サプライヤー</label>
        </div>
        <div class="connector-left-down"></div>
        <div class="center-gray"></div>      
        <div class="ship">
          <img src="@/assets/ship.svg"/>
          <label>船便</label>
        </div>
      </div>      
    </div>
    <div class="flow-sp sp">
      <img src="@/assets/inspection-service-sp.svg" />
    </div>
  </div>
  <div class="features">
    <h1>
      弊社サービスの特徴
    </h1>
    <div class="circles">
      <div class="circle">
        <span>複数箇所荷物を</span><span>まとめて発送</span>
      </div>
      <div class="circle">
        <span>FBA直送対応</span>
      </div>
      <div class="circle">
        <span>梱包材の対応も</span>可能
      </div>
      <div class="circle">
        <span>現地での保管は</span>1ヶ月まで無料
      </div>
    </div>
  </div>
  <div class="plan">
    <h1>料金プラン</h1>
    <table class="pc" cellspacing="0">
      <tr>
        <td class="transparent"></td>
        <td>エア便</td>
        <td>船便</td>
        <td>コンテナー</td>          
      </tr>
      <tr>
        <td>お届け先</td>
        <td>
          <div>日本国内ご指定の場所</div>
          <div>Amazon倉庫</div>
        </td>
        <td>
          <div>日本国内ご指定の場所</div>
          <div>Amazon倉庫</div>
        </td>
        <td>
          <div>Amazon倉庫のみ</div>
        </td>
      </tr>
      <tr>
        <td>到着日数</td>
        <td>約3-7日</td>
        <td>約7-12日</td>
        <td>約15-30日</td>        
      </tr>
      <tr>
        <td>料金目安</td>
        <td>25~35元/KG</td>
        <td>13元/KG</td>
        <td>900元/㎥</td>        
      </tr>
      <tr>
        <td>関税の支払い</td>
        <td>
          <div>着払い</div>          
          <div>請求書払い</div>
        </td>
        <td>
          請求書払い
        </td>
        <td>
          請求書払い
        </td>
      </tr>
      <tr>
        <td>特徴</td>
        <td>
          少量でスピードが求められた場合がお勧め
        </td>
        <td>
          100KG以上の場合がお勧め
        </td>
        <td>
          大量仕入れにはお勧め
        </td>
      </tr>                             
    </table>
    <div class="sp">
      <div class="service-block">
        <h3>エア便</h3>
        <table>
          <tr>
            <td>お届け先</td>
            <td>
              日本国内ご指定の場所、Amazon倉庫
            </td>
          </tr>
          <tr>
            <td>
              到着日数
            </td>
            <td>
              約3-7日
            </td>
          </tr>
          <tr>
            <td>
              料金目安
            </td>
            <td>
              25~35元/KG
            </td>
          </tr>
          <tr>
            <td>
              関税の支払い
            </td>
            <td>
              着払い<br>
              請求書払い
            </td>
          </tr>
          <tr>
            <td>特徴</td>
            <td>少量でスピードが求められた場合がお勧め</td>
          </tr>
        </table>
      </div>
      <div class="service-block">
        <h3>船便</h3>
        <table>
          <tr>
            <td>お届け先</td>
            <td>日本国内ご指定の場所、Amazon倉庫</td>
          </tr>
          <tr>
            <td>到着日数</td>
            <td>約7-12日</td>
          </tr>
          <tr>
            <td>料金目安</td>
            <td>25~35元/KG</td>
          </tr>
          <tr>
            <td>関税の支払い</td>
            <td>着払い<br>請求書払い</td>
          </tr>
          <tr>
            <td>特徴</td>
            <td>100KG以上の場合がお勧め</td>
          </tr>          
        </table>
      </div>
      <div class="service-block">
        <h3>コンテナー</h3>
        <table>
          <tr>
            <td>お届け先</td>
            <td>Amazon倉庫</td>
          </tr>
          <tr>
            <td>到着日数</td>
            <td>約15-30日</td>
          </tr>
          <tr>
            <td>料金目安</td>
            <td>900元/㎥</td>
          </tr>
          <tr>
            <td>関税の支払い</td>
            <td>請求書払い</td>
          </tr>
          <tr>
            <td>特徴</td>
            <td>大量仕入れにはお勧め</td>
          </tr>
        </table>
      </div>
    </div>
  </div>  
  <div class="faqs">
    <h1>よくあるご質問</h1>
    <faq
      v-for="(faq, index) in faqs"
      :key="index"
      :question="faq.question"
      :answer="faq.answer"
      />
  </div>  
</template>

<script>
import Faq from '@/components/atoms/Faq.vue'
import ServiceMenu from '../components/molecules/ServiceMenu.vue'

export default {
  name: 'Service',
  components: {
    Faq, ServiceMenu,
  },  
  data() {
    return {
      faqs: [
        {
          question: '商品の保険は国際送料に含まれていますか？',
          answer: '保険は国際送料に含まれておりません。中国の配送業者と日本の配送業者が異なるため、中国より商品を発送し、日本へ到着するまでの保険は弊社では加入しておりません。',
        },
        {
          question: '商品の配送先について指定はできますか。',
          answer: 'エア便と船便の場合、配送先の指定は可能でございます。'
        },
        {
          question: '港まで荷物を取りに行き、送料を節約することは可能ですか？',
          answer: '大変申し訳ございませんが、港での受け渡しは行っておりません。'
        },
        {
          question: '関税はどのようになりますか？',
          answer: '商品内容、商品数量によって通関手数料、関税、消費税、検疫調査料等がかかります。このような場合の費用はお客様のご負担となりますので、予めご了承ください。'
        }
      ]      
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.service-top {
  background: url('../assets/logistics-top.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
  .content {
    background: rgba(51,51,51,0.78);
    height: 100%;
    padding-left: 8%;
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    h1 {
      border-bottom: 1px solid white;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 50px;
    }
  }
}
.flows {
  background: #F7F7F7;
  padding: 60px 0px;
  h1 {
    text-align: center;
    margin-top: 0;
  }
  .box {
    background-color: #F7F7F7;
    width: 85%;
    margin: auto;
    padding: 30px 20px 50px 20px;
    .row {
      display: flex;
      justify-content: center;
      .supplier {
        width: 220px;
        height: 220px;
        border-radius: 110px;
        background-color: white;
        label {
          z-index: 2;
          font-weight: bold;
          color: #F2B504;
          background: white;
          padding: 5px 10px;
          border-radius: 5px;
          margin-top: 210px;
          margin-left: -100px;
          font-size: 22px;          
        }
      }
      .connector-left {
        transform: rotate(115deg);
        background: white;
        width: 30px;
        margin-top: -40px;
      }
      .connector-right {
        transform: rotate(60deg);
        background: white;
        width: 30px;
        margin-top: -40px;
        opacity: 0;   
      }      
      .connector-left-down {
        transform: rotate(60deg);
        background: white;
        width: 30px;
        margin-top: -100px;
      }
      .connector-right-down {
        transform: rotate(115deg);
        background: white;
        width: 30px;
        margin-top: -100px;
        opacity: 0; 
      }         
      .center {
        width: 300px;
        height: 300px;
        border-radius: 150px;
        background: white;
        margin-top: 125px;
        display: flex;
        align-items: center;
        justify-content: center;     
        position: relative;
        img {
          width: 85%;
        }
      }
      .center-gray {
        width: 300px;
        opacity: 0;
      }
      .airplane {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        background-color: white;
        margin-top: 65px;
        margin-left: 30px;
        label {
          z-index: 2;
          font-weight: bold;
          color: #F2B504;
          background: white;
          padding: 5px 10px;
          border-radius: 5px;
          margin-top: -190px;
          margin-right: -180px;
          font-size: 22px;
          position: absolute;    
        }
      }
      .ship {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        background-color: white;
        margin-top: -65px;
        margin-left: 30px;        
        label {
          z-index: 2;
          font-weight: bold;
          color: #F2B504;
          background: white;
          padding: 5px 10px;
          border-radius: 5px;
          margin-top: 190px;
          margin-right: -180px;
          font-size: 22px;    
        }        
      }
      .supplier, .airplane, .ship {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;        
        img {
          position: absolute;
          z-index: 1;
          width: 90%;
        }
      }   
    }
    .row-down {
      margin-top: -80px;
    }
  }
}
.features {
  background-color: #F2B504;
  color: white;
  text-align: center;
  padding: 20px 0 50px 0;
  font-weight: bold;
  h1 {
    margin: 0;
    padding: 30px 0;
  }
}
.faqs {
  h1 {
    text-align: center;
  }
}

@media (min-width: 751px) {
  .plan {
    background: #F7F7F7;
    padding-bottom: 100px;
    h1 {
      text-align: center;
      padding: 80px 0 30px 0;
    }
    table {
      width: 80%;
      margin: auto;
      padding: 3px;
      td {
        border: solid 5px #F7F7F7;
        padding: 20px 10px;
        text-align: center;
        background: white;
        width: 25%;
      }
      td:first-child {
        background: #606060;
        color: white;
        text-align: center;
        font-weight: bold;
      }
      .transparent {
        background: transparent !important;
      }    
    }
  }
  .features {
    margin-bottom: -22px;
    &::before {
      content: "";
      border-top: 30px solid transparent;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #F2B504;
      position: relative;
      top: -70px;
    }
    .circles {
      display: flex;
      justify-content: center;
      .circle {
        width: 160px;
        height: 160px;
        border: 2px solid white;
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 20px;
        span {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {  
.service-top {
  background: url('../assets/logistics-top-sp.png');
  background-size: cover;
}
.customer-voice {
    .customer-voice-row {
      flex-direction: column;
      .voice:not(:nth-child(1)) {
        margin-top: 30px;
      }
    }
  }
  .flows {
    .flow-sp {
      img {
        width: 100%;
      }
    }
  }
  .plan {
    background: #F7F7F7;
    padding-bottom: 50px;
    h1 {
      text-align: center;
      padding-top: 40px;
    }
    .service-block {
      background-color: white;
      width: 85%;
      padding: 5px 5px 30px 5px;
      margin: 20px auto;
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
      font-size: 14px;
      h3 {
        margin-bottom: 20px;
        font-weight: bold;
        text-align: center;
      }
      table {
        text-align: center;
        width: 90%;
        margin: auto;
        margin-top: 10px;
        tr {
          td:first-child {
            width: 40%;
            padding: 10px 0px;
          }
          td:nth-child(2) {
            font-weight: bold;
            text-align: left;
          }
        }  
      }
    }
  }
  .features {
    margin-bottom: -22px;
    p {
      width: 65%;
    }
    &::after {
      top: -370px;
    }
    .circles {
      flex-direction: column;
      .circle {
        text-align: left;
        text-indent: 50px;
        margin-bottom: 13px;
        &::before {
          content: '';
          margin-left: 10px;
          margin-right: 10px;
          border-left: 8px solid white;
        }
      }
    }    
  }
}
</style>